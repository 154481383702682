import "../App.css";
import  { React, useState, useEffect } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Col, Row, Modal,
  FloatButton, Menu, theme, Drawer, notification, Skeleton,
  Popover, Card, InputNumber, Select} from 'antd';
import { flushSync } from 'react-dom'; 
import { Route, Routes, useNavigate } from "react-router-dom";
import LOGIN from './LOGIN';
const PLATFORMA = () => {
  const navigate = useNavigate()
  return <div>
    <h1 className="h1">Платформа</h1>
    <div className="login-platforma">
      <LOGIN />
      <br/> <br/>   <br/> <br/>
      <br/> <br/>   <br/> <br/>
      <br/> <br/>   <br/> <br/>
      <br/> <br/>   <br/> <br/><br/> <br/>   <br/> <br/>
      <br/> <br/>   <br/> <br/>
    </div>
    <FloatButton.BackTop />
  </div>
};
export default PLATFORMA;


