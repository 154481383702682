import '../App.css';
import { useState, React } from 'react';
import {  Col, Row, Button, FloatButton, Card } from 'antd';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import PROJEKAT from '../Stranice/Upit/Projekat';
import PLATFORMA from '../Stranice/Upit/Platforma';
import KONSALTING from '../Stranice/Upit/Konsalting';
import PARTNERSTVO from '../Stranice/Upit/Partnerstvo';
const UPIT = () => {
  const [activeCard, setActiveCard] = useState(0);
  const handleNextCard = () => {
    setActiveCard((prevActiveCard) => (prevActiveCard + 1) % 4);
  };
  const handlePrevCard = () => {
    setActiveCard((prevActiveCard) => (prevActiveCard - 1 + 4) % 4);
  };
  const handleShortcutClick = (index) => {
    setActiveCard(index);
  };
  return (<div>
        <h1
          className="h1">
          Упит
        </h1>
        <br/>
        <Card bordered={false} className="card-upit">
      <Row align="middle">
        <Col span={2} align="middle">
          <Button shape="circle" type="text" onClick={handlePrevCard}>
            <LeftCircleOutlined />
          </Button>
        </Col>
        <Col span={10} align="middle">
          <Button
            style={{ fontFamily: 'MojFont', width: '120px', marginBottom: 0 }}
            type="text"
            onClick={handleShortcutClick.bind(null, 0)}
          >
            Пројекат
          </Button>
          <Button
            style={{ fontFamily: 'MojFont', width: '120px' }}
            type="text"
            onClick={handleShortcutClick.bind(null, 1)}
          >
            Платформа
          </Button>
        </Col>
        <Col span={10} align="middle">
          <Button
            style={{ fontFamily: 'MojFont', width: '120px', marginBottom: 0 }}
            type="text"
            onClick={handleShortcutClick.bind(null, 2)}
          >
            Консалтинг
          </Button>
          <Button
            style={{ fontFamily: 'MojFont', width: '120px' }}
            type="text"
            onClick={handleShortcutClick.bind(null, 3)}
          >
            Партнерство
          </Button>
        </Col>
        <Col span={2} align="middle" >
          <Button shape="circle" type="text" onClick={handleNextCard}>
            <RightCircleOutlined />
          </Button>
        </Col>
      </Row>
    </Card>
    <br/>
    <br/>
        {activeCard === 0 && (
          <Card 
          title="Пројекат" 
          className="card-upit" 
          bordered={false} 
          >
            <PROJEKAT handleShortcutClick={handleShortcutClick}/>
          </Card>
        )}
        {activeCard === 1 && (
          <Card
            title="Платформа"
            className="card-upit"
            bordered={false}
          >
            <PLATFORMA/>
          </Card>
        )}
        {activeCard === 2 && (
          <Card
            title="Консалтинг"
            className="card-upit"
            bordered={false}
          >
            <KONSALTING/>
          </Card>
        )}
        {activeCard === 3 && (
          <Card
            title="Партнерство"
            className="card-upit"
            bordered={false}
          >
            <PARTNERSTVO/>
          </Card>
        )}
    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
    <FloatButton.BackTop />
  </div>);
};
export default UPIT;