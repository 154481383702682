import '../../App.css';
import React, { useState, useEffect } from 'react';
import { flushSync } from 'react-dom';  //За штампање
import {  Col,        Row,    Button, Upload,       Cascader, Checkbox,
  DatePicker, ConfigProvider, Form,   Input,  InputNumber,  Radio,    Select,
  Slider,     Switch, TreeSelect, FloatButton, Card, AutoComplete, 
  } from 'antd';
import {  UploadOutlined, PlusOutlined, MinusCircleOutlined, UserOutlined, 
          LeftCircleOutlined, RightCircleOutlined, PlusCircleOutlined } 
  from '@ant-design/icons';
import { DndContext, PointerSensor, useSensor } 
  from '@dnd-kit/core';
import { SortableContext, arrayMove, useSortable, verticalListSortingStrategy, } 
  from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { css } from '@emotion/css';
import SLANJE from './SLANJE';
import moment from 'moment';
import srRS from 'antd/lib/locale/sr_RS';
const { RangePicker } = DatePicker;
const customStyle = {
  fontFamily: 'MojFont',
};
const options3 = [
  { 
    value: 'Солар',
    style: customStyle, 
  },
  { 
    value: 'Енергетски претварачи', 
    style: customStyle, 
  },
  { 
    value: 'Телеметрија', 
    style: customStyle, 
  },
  { 
    value: 'IOT', 
    style: customStyle, 
  },
  { 
    value: 'Паметне куће', 
    style: customStyle, 
  },
  { 
    value: 'Агрономиа', 
    style: customStyle, 
  },
  { 
    value: 'SCADA', 
    style: customStyle, 
  },
  { 
    value: 'Гас', 
    style: customStyle, 
  },
  { 
    value: 'Медицина', 
    style: customStyle, 
  },
];
const { Option } = Select;
const { TextArea } = Input;
const PROJEKAT = ( { handleShortcutClick } ) => {
  const onFinish = (values) => {    
    showModal();
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
const [options2, setOptions2] = useState([]);
const [inputValue, setInputValue] = useState('');
const handleSearch = (value) => {
  setInputValue(value);
  let res = [];
  if (!value || value.indexOf('@') >= 0) {
    res = [];
  } else {
    res = ['ism-studio.rs', 'yandex.com', 'yahoo.com', 'vivaldi.net', 'gmail.com'].map((domain) => ({
      
      value: `${value}@${domain}`,
      style: customStyle,
    }));
  }
  setOptions2(res);
};
const onSelect2 = (value) => {
  setInputValue(value);
};
  const [fields, setFields] = useState([{ name: '', id:1 }]);
  const addField = () => {
    const newId = fields.length > 0 ? fields[fields.length - 1].id + 1 : 1;
    setFields([...fields, { name: '', id: newId }]);
  };
  const removeField = (id) => {
    const newFields = fields.filter((field) => field.id !== id);
    setFields(newFields);
  };
  const handleNameChange = (value, id) => {
    const newFields = [...fields];
    const fieldToUpdate = newFields.find((field) => field.id === id);
    if (fieldToUpdate) {
      fieldToUpdate.name = value;
      setFields(newFields);
    }
  };
  const [unos, setUnos] = React.useState('');
  const handleChange = (event) => {
    setUnos(event.target.value);
  };
  const prefixSelector = (
    <Select className="my-select"  style={{ width: 80, }}>
      <Option className="my-select" value="81">+381</Option>
      <Option className="my-select" value="82">+382</Option>
      <Option className="my-select" value="83">+383</Option>
      <Option className="my-select" value="85">+385</Option>
      <Option className="my-select" value="86">+386</Option>
      <Option className="my-select" value="87">+387</Option>
      <Option className="my-select" value="89">+389</Option>
    </Select>
  );
  const suffixSelector = (
      <Select 
        className="my-select"
        style={{ width: 80 }}
      >
        <Option className="my-select" value="DIN">дин.</Option>
        <Option className="my-select" value="EUR">€</Option>
        <Option className="my-select" value="RUB">₽</Option>
        <Option className="my-select" value="USD">$</Option>
      </Select>
  );
  const [componentDisabled, setComponentDisabled] = useState(false);
  const [componentDisabled2, setComponentDisabled2] = useState(false);
  return (    <Form
                onFinish={onFinish}
                labelCol={{
                  span: 10, }}
                wrapperCol={{
                  span: 40, }}
                layout="vertical"
                className="custom-form-item"  
                style={{ marginLeft: '5%', marginRight: '5%'}}
                >        
                <Form.Item
                  label="Име"
                  name="ime"
                  rules={[
                    {
                      required: true,
                      message: false,
                    },
                  ]}
                  style={{ fontFamily: 'MojFont' }}>
                  <Input style={{fontFamily: 'MojFont'}}/>
                </Form.Item>
                <Form.Item 
                  label="Презиме"
                  name="prezime"
                  rules={[
                    {
                      required: true,
                      message: false,
                    },
                  ]}
                  style={{ fontFamily: 'MojFont' }}>
                  <Input style={{fontFamily: 'MojFont'}}/>
                </Form.Item>
                <Form.Item 
                  name="tele"
                  label="Телефон"
                  rules={[
                    {
                      required: true,
                      message: false,
                    },
                    {
                      validator: (_, value) => {
                        if (!value || !/^[+\d]{8,15}$/.test(value)) {
                          return Promise.reject('');
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input
                    inputMode="tel"
                    style={{ backgroundColor: 'white', borderRadius: '7px' }}
                    addonBefore={
                      <span style={{ fontFamily: 'MojFont', width: 80}}>
                        {prefixSelector}
                      </span>
                    }
                  />
                </Form.Item>
                <Form.Item 
                  style={{fontFamily: 'MojFont'}}
                  label="Мејл"
                  name="mejl"
                  required
                  labelAlign="top"
                  rules={[
                    {
                      required: true,
                      message: false,
                      type: "email",
                    },
                    {
                    validator: (_, value) => {
                      if (!value || !/^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/i.test(value)) {
                        return Promise.reject('');
                      }
                      return Promise.resolve();
                    },
                  },
                  ]}
                >
                  <AutoComplete
                    style={{fontFamily: 'MojFont'}}
                    onSearch={handleSearch}
                    options={options2}
                  >
                    <Input inputMode="email"/>
                  </AutoComplete>
                </Form.Item>
                <Form.Item 
                  label="ПИБ"
                  name="pib"
                  tooltip={
                    <span style={{ fontFamily: 'MojFont' }}>
                      ИШМ студио је <i>”B2B”</i>
                    </span>
                  }
                  rules={[
                    {
                      required: true,
                      message: false,
                    },
                    {
                      pattern: /^\d{9}$/,
                      message: false,
                    },
                  ]}
                  style={{ fontFamily: 'MojFont' }}
                >
                  <Input
                    inputMode="numeric"
                    maxLength={9}
                    style={{ fontFamily: 'MojFont'}}
                  />
                </Form.Item>
                <Form.Item 
                  label="Област">
                  <AutoComplete
                    popupClassName="certain-category-search-dropdown"
                    style={{ fontFamily: 'MojFont' }}
                    options={options3}
                  >
                    <Input 
                      style={{
                      fontFamily: 'MojFont' }}
                      size="large" 
                      />
                  </AutoComplete>
                </Form.Item>
                <Form.Item
                  label="Опис пројекта"
                  name="OP"
                  tooltip=
                  {
                    <span style={{ fontFamily: 'MojFont' }}>
                      У кратким цртама опишите повод упита, који 
                           су планови пројекта, и како Вам ту можемо помоћи.
                    </span>
                  }
                  rules={[
                    {
                      required: true,
                      message: <span style={{ fontFamily: 'MojFont' }}>Унесите који је повод упита.</span>,
                    },
                  ]}>
                  <TextArea rows={4} style={{fontFamily: 'MojFont'}}/>
                </Form.Item>
                <Checkbox 
                  defaultChecked={false}
                    checked={componentDisabled}
                    onChange={(e) => setComponentDisabled(e.target.checked)}
                    style={{
                      fontFamily: 'MojFont'
                    }}
                  >
                    Имате ли план развоја?
                </Checkbox>
                <br/><br/>
                <Form.Item 
                  label="План развоја"
                  disabled={componentDisabled}
                  tooltip=
                  {
                    <span style={{ fontFamily: 'MojFont' }}>
                      У кратким цртама опишите план развоја. У пољима испод 
                          додајте кроз које фазе мора пројекат да прође да би дошли до жељеног циља.
                    </span>
                  }
                  style={{ display: !componentDisabled ? 'none' : 'block', fontFamily: 'MojFont' }}
                  name="pRazvoja">
                  <TextArea 
                    rows={2} 
                    style={{fontFamily: 'MojFont'}}
                    placeholder="Опис плана развоја..." />
<br/><br/>
                  {fields.map((field, index) => (  
                  <Form.Item
                    key={field.id}
                    align="baseline"
                    name={`name-${field.id}`}
                    label={index === 0 ? 'Додај предлоге фаза развоја' : ``}
                    fieldKey={`name-${field.id}`}
                  >
                    <Input
                      value={field.name}
                      onChange={(e) => handleNameChange(e.target.value, field.id)}
                      suffix={
                        <>
                          {fields.length > 1 && (
                            <MinusCircleOutlined
                              style={{ marginRight: '4px', cursor: 'pointer', color: '#333' }}
                              onClick={() => removeField(field.id)}
                            />
                          )}
                          <PlusCircleOutlined onClick={addField} style={{ cursor: 'pointer', color: '#333' }} />
                        </>
                      }
                    />
                  </Form.Item>
                  ))}
                </Form.Item>
                <Form.Item 
                  label="Фаза прототипских верзија"
                  labelCol={{ span: 16 }} 
                  name="brf" 
                  style={{
                    fontFamily: 'MojFont', 
                    }}
        rules={[
          {
            type: 'number',
            min: 1,
            max: 10,
            message: false,
          },
        ]}
      >
        <InputNumber 
        label="Фаза прототипских верзија"
          id="obican_input_number"
          inputMode="numeric"
          style={{
            fontFamily: 'MojFont'}}/>
                </Form.Item>
                  <Checkbox
                    style={{
                      fontFamily: 'MojFont'
                    }}
                    onChange={(e) => setComponentDisabled2(e.target.checked)}
                  >
                    Да ли још неко учествује у пројекту?
                  </Checkbox>
                  <br/><br/>
                  <Form.Item 
                    name="ucesnici"
                    style={componentDisabled2 ? { display: 'block' } : { display: 'none' }}
                  >
                    <TextArea 
                      rows={2} 
                      style={{fontFamily: 'MojFont'}}  
                      placeholder="Набројте ко све учествује у пројекту и њихове контакте." />
                      <br/><br/>
                  </Form.Item>
                <Form.Item 
                  name="remember" 
                  valuePropName="checked" 
                  style={{
                    fontFamily: 'MojFont',
                    display: 'flex',  justifyContent: 'flex-start', 
                  }}
                  >
                  <Checkbox style={{fontFamily: 'MojFont'}}>Студија изводљивости</Checkbox><br/>
                  <Checkbox style={{fontFamily: 'MojFont'}}>План развоја</Checkbox><br/>
                  <br/>
                  <Checkbox style={{fontFamily: 'MojFont'}}>Развој</Checkbox><br/>
                  <Checkbox style={{fontFamily: 'MojFont'}}>Подршка</Checkbox><br/>
                  <Checkbox style={{fontFamily: 'MojFont'}}>Производња</Checkbox><br/>
                  <br/>
                  <Checkbox style={{fontFamily: 'MojFont'}}>Хардвер</Checkbox><br/>
                  <Checkbox style={{fontFamily: 'MojFont'}}>Фирмвер</Checkbox><br/>
                  <Checkbox style={{fontFamily: 'MojFont'}}>Софтвер</Checkbox><br/>
                  <br/>
                  <Checkbox style={{fontFamily: 'MojFont'}}>Рендери</Checkbox><br/>
                  <Checkbox style={{fontFamily: 'MojFont'}}>Симулације</Checkbox><br/>
                  <Checkbox style={{fontFamily: 'MojFont'}}>Документација</Checkbox><br/>
                  <Checkbox style={{fontFamily: 'MojFont'}}>Идејно решење</Checkbox><br/>
                  <br/>
                  <Checkbox style={{fontFamily: 'MojFont'}}>Тестови</Checkbox><br/>
                  <Checkbox style={{fontFamily: 'MojFont'}}>Уређаја за тестирање</Checkbox><br/>
                  <br/>
                  <Checkbox style={{fontFamily: 'MojFont'}}>Кључ у руке</Checkbox><br/>
                  <Checkbox style={{fontFamily: 'MojFont'}}>Развој већ постојећег пројекта</Checkbox><br/>
                  <Checkbox style={{fontFamily: 'MojFont'}}>Део тима</Checkbox><br/>
                  <Checkbox style={{fontFamily: 'MojFont'}}>Партнерство</Checkbox><br/>
                  <br/>
                </Form.Item>
                <Form.Item 
                  label="Рок до"
                  name="datumVreme"
                >
                  <Input
                    type="datetime-local"
                    style={{
                      fontFamily: 'MojFont',
                    }}
                  />
                  <TextArea 
                    rows={2} 
                    style={{fontFamily: 'MojFont'}}  
                    placeholder="Више о роковима..." />
                </Form.Item>
                <Form.Item
                  name="budget"
                  label="Буџет"
                >
                  <InputNumber
                    inputMode="numeric"
                    style={{
                      width: '100%',
                      fontFamily: 'MojFont', 
                      backgroundColor: 'white', 
                      borderRadius: '7px' }}
                    addonAfter={suffixSelector}
                  />
                </Form.Item>
                <Form.Item
                  label="Ризици"
                  name="rizici"
                >
                  <TextArea 
                    rows={4} 
                    style={{fontFamily: 'MojFont'}}  
                    placeholder="Набројте све потенцијалне ризике..." />
                </Form.Item>
                <Form.Item 
                  label="Финансирање"
                  name="finansiranje">
                  <TextArea 
                    rows={4}  
                    style={{fontFamily: 'MojFont'}} 
                    placeholder="Унесите одакле се финансира пројекат..." />
                </Form.Item>
                <Form.Item 
                  label="Сигурност"
                  name="zastite">
                  <TextArea 
                    rows={4} 
                    style={{fontFamily: 'MojFont'}}  
                    placeholder="Набројте које заштитне мере желите..." />
                </Form.Item>
                <Form.Item
                >
                  <Button
                    type="primary"
                    htmlType="submit" 
                    className="login-form-button"
                    >
                    Пошаљи захтев
                  </Button>
                </Form.Item>
                <SLANJE visible1={isModalVisible} onCancel1={handleCancel} />
                <Button
                    className="login-form-button"
                    style={{ 
                      color: 'blue',
                      }} 
                    type="text" 
                    onClick={() => { 
                      handleShortcutClick(2); 
                      window.scrollTo(0, 0); 
                    }}
                  >
                    Или ипак консалтинг?
                  </Button>
  </Form>
  );
};
export default PROJEKAT;