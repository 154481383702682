import "./App.css";
import  { React, useState, useEffect } from 'react';
import { Route, Routes, useNavigate } from "react-router-dom";
import {  Button,       Layout,   
  Menu,   theme,        Drawer, 
  Modal,  notification, Popover,
  Col,    Row,          Space  } from
'antd';
import {
  LineChartOutlined,  QuestionCircleOutlined, SettingOutlined,
  MailOutlined,       BellOutlined,           IdcardOutlined, 
  DoubleLeftOutlined, DoubleRightOutlined,           
  UserOutlined,       DashboardOutlined,      HomeOutlined,
  ContactsOutlined,   TranslationOutlined,        
  BookOutlined,       OrderedListOutlined, FolderOpenOutlined, FormOutlined} from
'@ant-design/icons';  
import POCETNA from "./Stranice/POCETNA";
import PLATFORMA from './Stranice/PLATFORMA';
import ISMEDU from './Stranice/ISMEDU';
import ONAMA from './Stranice/ONAMA';
import KONTAKT from './Stranice/KONTAKT';
import UPIT from './Stranice/UPIT';
import LOGIN from './Stranice/LOGIN';
const text = <span style={{fontFamily: 'MojFont'}}>Језик</span>;
const content = (
  <div style={{fontFamily: 'MojFont'}}>
    <Button 
      type="text" 
      style={{fontFamily: 'MojFont'}}>
        Српски (ћирилица)
    </Button>
  </div>
);
const buttonWidth = 70;
const { Header, Sider, Content } = Layout;
const App = () => {
const [isOpen, setIsOpen] = useState(false);
const toggleMenu = () => {
  setIsOpen(!isOpen);
};
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const navigate = useNavigate()
  const [collapsed, setCollapsed] = useState(false);
  const {token: { colorBgContainer },} = theme.useToken();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const showDrawer = () => {
    setIsDrawerOpen(true);
  };
  const onClose = () => {
    setIsDrawerOpen(false);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
      const [isModalVisible3, setIsModalVisible3] = useState(false);
      const toggleModal3 = () => {
        setIsModalVisible3(!isModalVisible3);
      };  
      const handleMenuSelect = (key) => {
        toggleModal3(); 
        navigate(key);
      };
      const [isModalVisible4, setIsModalVisible4] = useState(false);
      const toggleModal4 = () => {
        setIsModalVisible4(!isModalVisible4);
      };  
      const handleMenuSelect4 = () => {
        toggleModal4();
      };
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type) => {
    api[type]({
      message: 'Нисте пријављени!',
      description:
        'Морате бити пријављени да бисте добијали обавештења.',
      style: { fontFamily: 'MojFont'},
    });
  };
  return (
    <Layout>
      {isMobile ? (
        <Layout>
          <Header
            style={{ 
              position: 'fixed',
              display: 'flex', 
              justifyContent: 'flex-end', alignItems: 'flex-start',
              padding: 10,
              background: 'transparent',
              opacity: 0.9,
              zIndex: 1, width: '100%',
              }}
              >
          <Button
            type="primary"
            shape="circle"
            className={`menu ${isModalVisible3 ? 'opened' : ''}`}
            onClick={toggleModal3}
            aria-label="Main Menu"
            style={{ zIndex: 5, 
              fontFamily: 'MojFont', 
              padding: 15,
              margin: 0,
              width: '80px', 
              height: '80px',
              background: 'rgba(245, 245, 245, 0.5)', 
              opacity: 0.9,
              borderColor: '#f5f5f5',
             }}
          >
            <svg width="50" height="50" viewBox="0 0 100 100">
              <path className="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
              <path className="line line2" d="M 20,50 H 80" />
              <path className="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
            </svg>
          </Button>
          </Header>
          <Modal
            open={isModalVisible3}
            onCancel={toggleModal3}
            footer={null}
            closable={false}
          >
            <Row>
              <Col 
                span={12} 
                style={{
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center', 
                  }}>
              <Menu
                onClick={({key})=>{
                  handleMenuSelect(key);
                }}
                mode="inline"
                defaultSelectedKeys={['1']}
                style={{
                  fontFamily: 'MojFont',
                  border: 'none',         
                  background: 'none',     
                  width: '160px',     
                  margin: 0,
                  padding: 0
              }}
                  items={[
                  {
                    key: '/',
                    icon: <HomeOutlined />,
                    label: 'Почетна',
                  },
                  {
                    key: '/О_нама',
                    icon: <IdcardOutlined />,
                    label: 'О нама',
                  },

                  {
                    key: '/Платформа',
                    icon: <DashboardOutlined />,
                    label: 'Платформа',
                  },
                  {
                    key: '/ИШМ_ЕДУ',
                    icon: <BookOutlined />,
                    label: 'ИШМ ЕДУ',
                  },
                  {
                    key: '/Упит',
                    icon: <QuestionCircleOutlined />,
                    label: 'Упит',
                  },
                  {
                    key: '/Контакт',
                    icon: <ContactsOutlined />,
                    label: 'Контакт',
                  },
                ]}
              />
              </Col>
              <Col 
                span={12} 
                style={{
                  display: 'flex', 
                  justifyContent: 'center', 
                }}>
              <Menu
                onClick={({ key }) => {
                  handleMenuSelect4(key);
                }}
                mode="inline"
                selectedKeys={[]}         
                style={{
                  fontFamily: 'MojFont',
                  border: 'none',        
                  background: 'none',    
                  width: '170px',        
                  margin: 0,
                  padding: 0
              }}
                  items={[
                  {
                    icon: <MailOutlined />,
                    label: 'Поруке',
                  },
                  {
                    icon: <FormOutlined />,
                    label: 'Тикети',
                  },
                  {
                    icon: <BellOutlined /> ,
                    label: 'Обавштење',
                  },
                  {
                    icon: <SettingOutlined />,
                    label: 'Подешавања',
                  },
                  {
                    icon: <QuestionCircleOutlined /> ,
                    label: 'Нови упит',
                  },
                  {
                    icon: <FolderOpenOutlined />,
                    label: 'Пројекти',
                  },
                  {
                    icon: <OrderedListOutlined />,
                    label: 'Требовања',
                  },
                  {
                    icon: <LineChartOutlined />,
                    label: 'Статистике',
                  },
                ]}
              />
              </Col>
            </Row>
          </Modal>
          <Modal
            open={isModalVisible4}
            onCancel={toggleModal4}
            footer={null} 
            closable={false}
          >
            <h1 className="h1"> Пријава на налог </h1>
            <LOGIN />
          </Modal>
      <Content style={{ overflow: 'auto', height: '100%' }}>
        <Routes>
          <Route path="/" element={<POCETNA/>}/>
          <Route path="/О_нама" element={<ONAMA/>}/>
          <Route path="/Платформа" element={<PLATFORMA/>} />
          <Route path="/ИШМ_ЕДУ" element={<ISMEDU/>}/>
          <Route path="/Упит" element={<UPIT/>}/>
          <Route path="/Контакт" element={<KONTAKT/>}/>
        </Routes>
      </Content>
    </Layout>  
  ) : (
    <>
      <Sider trigger={null} 
      collapsible 
      hoverable
      collapsed={collapsed} 
      style={{ paddingTop: 0, paddingBottom: 1000, backgroundColor: '#336699',}}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'end', 
            width: '100%',
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <DoubleRightOutlined/> : <DoubleLeftOutlined/>}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              paddingLeft: '64%',
              paddingRight: '36%',
              color: 'white',
              width: 200,
              height: 64,
            }}>
          </Button>
        </div>
        <Menu
          onClick={({key})=>{
            navigate(key)
          }}
          theme="dark"
          mode="inline"
          defaultSelectedKeys={['1']}
          position="fixed"
          style={{
            fontFamily: 'MojFont',
            backgroundColor: '#336699', 
            color: 'white', 
             zIndex: 1,
             fontSize: 16,
          }}
          items={[
            {
              key: '/',
              icon: <HomeOutlined  style={{ fontSize: '20px' }}/>,
              label: 'Почетна',
            },
            {
              key: '/О_нама',
              icon: <IdcardOutlined  style={{ fontSize: '20px' }}/>,
              label: 'О нама',
            },

            {
              key: '/Платформа',
              icon: <DashboardOutlined  style={{ fontSize: '20px' }}/>,
              label: 'Платформа',
            },
            {
              key: '/ИШМ_ЕДУ',
              icon: <BookOutlined  style={{ fontSize: '20px' }}/>,
              label: 'ИШМ ЕДУ',
            },
            {
              key: '/Упит',
              icon: <QuestionCircleOutlined  style={{ fontSize: '20px' }}/>,
              label: 'Упит',
            },
            {
              key: '/Контакт',
              icon: <ContactsOutlined  style={{ fontSize: '20px' }}/>,
              label: 'Контакт',
            },
          ]}
        />
      </Sider>
    <Layout>
      <Header
        style={{ 
          display: 'flex', 
          justifyContent: 'end', 
          paddingTop: 15, 
          paddingRight: 0, 
          background: 'transparent',
          opacity: 0.9,
          }}>
            <Popover placement="topLeft" title={text} content={content} trigger="click">
              <Button 
                type="text"  
                style={{ fontFamily: 'MojFont'}}><TranslationOutlined /></Button>
            </Popover>
            <Button 
              onClick={showModal} 
              type="text"  
              style={{ fontFamily: 'MojFont'}}><BellOutlined />  </Button>
            <Button 
              onClick={showModal}  
              type="text"  
              style={{ fontFamily: 'MojFont', marginRight: '20px'}}><MailOutlined /></Button>
            <Button 
              style={{ marginRight: '40px' }}
              type="primary"
              onClick={showDrawer}
              Avatar icon={<UserOutlined />}
              shape="circle"
            >
            </Button>
    <Drawer 
      title="Ваш налог" 
      name="Drawer"
      open={isDrawerOpen}
      placement="right" 
      onClose={onClose} 
      style={{fontFamily: 'MojFont'}}>
        <Space direction="vertical">
          <Button 
            type="primary" 
            onClick={showModal} 
            style={{width: '150px', 
              marginLeft: 'auto', 
              fontFamily: 'MojFont'
            }}>
            Пријава
            </Button>
            {contextHolder}
              <Button onClick={() => openNotificationWithIcon('warning')} style={{width: '150px', marginLeft: 'auto', fontFamily: 'MojFont'}}><MailOutlined />Поруке</Button>
              <Button onClick={() => openNotificationWithIcon('warning')} style={{width: '150px', marginLeft: 'auto', fontFamily: 'MojFont'}}><FormOutlined />Тикети</Button>
              <Button onClick={() => openNotificationWithIcon('warning')} style={{width: '150px', marginLeft: 'auto', fontFamily: 'MojFont'}}><BellOutlined /> Обавштење</Button>
              <Button onClick={() => openNotificationWithIcon('warning')} style={{width: '150px', marginLeft: 'auto', fontFamily: 'MojFont'}}><SettingOutlined />Подешавања</Button>
              <Button onClick={() => openNotificationWithIcon('warning')} style={{width: '150px', marginLeft: 'auto', fontFamily: 'MojFont'}}><QuestionCircleOutlined /> Нови упит</Button>
              <Button onClick={() => openNotificationWithIcon('warning')} style={{width: '150px', marginLeft: 'auto', fontFamily: 'MojFont'}}><FolderOpenOutlined />Пројекти</Button>
              <Button onClick={() => openNotificationWithIcon('warning')} style={{width: '150px', marginLeft: 'auto', fontFamily: 'MojFont'}}><OrderedListOutlined />Требовања</Button>
              <Button onClick={() => openNotificationWithIcon('warning')} style={{width: '150px', marginLeft: 'auto', fontFamily: 'MojFont'}}><LineChartOutlined /> Статистике</Button>
            </Space>
          </Drawer>
        <Modal 
          open={isModalOpen} 
          nOk={handleOk} 
          onCancel={handleCancel}
          footer={null} 
          closable={false} >
            <h1 className="h1"> Пријава на налог </h1>
        <LOGIN />
        </Modal>
      </Header>
      <Content style={{ overflow: 'auto', height: '100%' }}>
        <Routes>
          <Route path="/" element={<POCETNA/>}/>
          <Route path="/О_нама" element={<ONAMA/>}/>
          <Route path="/Платформа" element={<PLATFORMA/>} />
          <Route path="/ИШМ_ЕДУ" element={<ISMEDU/>}/>
          <Route path="/Упит" element={<UPIT/>}/>
          <Route path="/Контакт" element={<KONTAKT/>}/>
        </Routes>
      </Content>
      </Layout>
      </>
  )}
    </Layout>
  );
};
export default App;