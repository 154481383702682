import "../App.css";
import  { React, useState, useEffect } from 'react';
import { LockOutlined, UserOutlined, ExclamationCircleOutlined
 } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Col, Row, Modal,
  FloatButton, Menu, theme, Drawer, notification, Skeleton,
  Popover, Card, InputNumber, Select, AutoComplete, Spin,
  } from 'antd';
import { flushSync } from 'react-dom'; 
import { Route, Routes, useNavigate } from "react-router-dom";
import SLANJE from './Upit/SLANJE';
const { Option } = Select;
const LOGIN = () => {
    const [loading, setLoading] = useState(false);
    const handleSubmit = async () => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        notification.open({
          placement:'top',
          message: 'Обавештење',
          description: 'Морате бити регистровани! Пошаљите мејл, или попуните форму за регистрацију.',
          icon: <ExclamationCircleOutlined style={{ color: 'red' }}/>,
          style: { fontFamily: 'MojFont'},
        });
      }, 5000);
    };
    const onFinish3 = (values) => {  
      setTimeout(() => {
        onCancel1()
      }, 5000);  
      handleSubmit();
    };
  const onFinish2 = (values) => {    
    visible1();
  };
  const onFinish = (values) => {    
    visible1();
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [isModalVisible1, setIsModalVisible1] = useState(false);
  const visible1 = () => {
    setIsModalVisible1(true);
  };
  const handleOk1 = () => {
    setIsModalVisible1(false);
  };
  const onCancel1 = () => {
    setIsModalVisible1(false);
  };
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const showModal2 = () => {
    setIsModalOpen2(true);
  };
  const handleOk2 = () => {
    setIsModalOpen2(false);
  };
  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };
 const customStyle = {
  fontFamily: 'MojFont',
};
const [options2, setOptions2] = useState([]);
const [inputValue, setInputValue] = useState('');

const handleSearch = (value) => {
  setInputValue(value);
  let res = [];
  if (!value || value.indexOf('@') >= 0) {
    res = [];
  } else {
    res = ['ism-studio.rs', 'yandex.com', 'yahoo.com', 'vivaldi.net', 'gmail.com'].map((domain) => ({
      
      value: `${value}@${domain}`,
      style: customStyle,
    }));
  }
  setOptions2(res);
};
const onSelect2 = (value) => {
  setInputValue(value);
};
  const prefixSelector = (
    <Select className="my-select"  style={{ width: 80,}}>
      <Option className="my-select" value="81">+381</Option>
      <Option className="my-select" value="82">+382</Option>
      <Option className="my-select" value="83">+383</Option>
      <Option className="my-select" value="85">+385</Option>
      <Option className="my-select" value="86">+386</Option>
      <Option className="my-select" value="87">+387</Option>
      <Option className="my-select" value="89">+389</Option>
    </Select>
  );
const currentDate = new Date();
const hours = currentDate.getHours();
const minutes = currentDate.getMinutes().toString().padStart(2, '0'); // Додајемо нуле испред минута
const day = currentDate.getDate();
const month = currentDate.getMonth() + 1; // Додати 1 јер месеци крећу од 0 (јануар је 0)
const year = currentDate.getFullYear();
const formattedDate = `${hours}.${minutes} ч. ${day}. ${month}. ${year}. године.`;
const [isPrinting, setIsPrinting] = useState(false);
useEffect(() => {
function handleBeforePrint() {
  flushSync(() => {
    setIsPrinting(true);
  })
}
function handleAfterPrint() {
  setIsPrinting(false);
}
window.addEventListener('beforeprint', handleBeforePrint);
window.addEventListener('afterprint', handleAfterPrint);
return () => {
  window.removeEventListener('beforeprint', handleBeforePrint);
  window.removeEventListener('afterprint', handleAfterPrint);
}
}, []);
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type) => {
    api[type]({
      message: 'Нисте пријављени!',
      description:
        'Морате се пријавити да би добијали обавештења',
    });
  };
  const navigate = useNavigate()
  return <Form
    name="login"
    className="login-form"
    initialValues={{
      remember: true,
    }}
    onFinish={onFinish3}
  >
    <br/>
    <Form.Item 
      name="username"
      rules={[
        {
          required: true,
          message: false,
        },
      ]}
    >
      <div className="input-group">
        <Input 
          className="input" 
          required 
          type="text"
          />
        <label className="user-label">Корисничко име</label>
      </div>
    </Form.Item>
    <br/>
    <Form.Item
      name="password"
      rules={[
        {
          required: true,
          message: false,
        },
      ]}
    >
      <div className="input-group">
        <Input
          className="input"
          required
          type="password"
        />
        <label className="user-label">Лозинка</label>
      </div>
    </Form.Item>
    <br/>
    <Form.Item>
      <Button type="primary" 
        htmlType="submit" 
        className="login-form-button"
        >
        Пријави се 
      </Button>
    </Form.Item>
    {loading && (
    <p 
    style={{marginLeft: 0, marginRight: 0, display: 'flex'}}
      className="text">
        <Spin 
          tip="slanje..."
          //size="large" 
          style={{fontFamily: 'MojFont', marginRight: 20}}
          />
        Повезивање...
    </p> 
    )}
    <a className="text4" type="primary" onClick={showModal2}>
        Заборављена лозинка?
    </a>
    <br/><br/>
    <a className="text4" type="primary" onClick={showModal}>
        Регистрација?
    </a>
    <Modal
      footer={null}
      closable={false} 
      open={isModalOpen2} 
      onOk={handleOk2} 
      onCancel={handleCancel2}>
        <h1 className="h1">Нова лозинка</h1>
      <Form
        name="normal_login"
        style={{fontFamily: 'MojFont'}}
        className="modal-form-item"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish2}
      >
        <Form.Item
          style={{fontFamily: 'MojFont'}}
          label="Мејл"
          name="email"
          required
          labelAlign="top"
          rules={[
            {
              required: true,
              message: false,
              type: "email",
            },
            {
            validator: (_, value) => {
              if (!value || !/^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/i.test(value)) {
                return Promise.reject('');
              }
              return Promise.resolve();
            },
          },
          ]}
        >
          <AutoComplete
            style={{fontFamily: 'MojFont'}}
            onSearch={handleSearch}
            options={options2}
          >
            <Input/>
          </AutoComplete>
        </Form.Item>
        <Form.Item 
          name="tele"
          label="Телефон"
          rules={[
            {
              required: true,
              message: false,
            },
            {
              validator: (_, value) => {
                if (!value || !/^[+\d]{8,15}$/.test(value)) {
                  return Promise.reject('');
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            inputMode="tel"
            addonBefore={
              <span style={{ fontFamily: 'MojFont', width: 80 }}>
                {prefixSelector}
              </span>
            }
          />
        </Form.Item>
        <Form.Item 
        >
          <br/>
          <Button 
            type="primary" 
            htmlType="submit" 
            className="login-form-button" 
            style={{fontFamily: 'MojFont'}}
            >
            Пошаљи захтев 
          </Button>
        </Form.Item>
        <SLANJE visible1={isModalVisible1} onCancel1={onCancel1} />
      </Form>
      <FloatButton.BackTop />
    </Modal>
    <Modal
      footer={null} 
      closable={false} 
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}>
      <h1 className="h1">Регистрација</h1>
      <Form
        name="normal_login"
        className="modal-form-item"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Form.Item 
          label="Име"
          name="ime"
          rules={[
            {
              required: true,
              message: false,
            },
          ]}
          style={{ fontFamily: 'MojFont' }}
          >
          <Input />
        </Form.Item>
        <Form.Item 
          label="Презиме"
          name="Презиме"
          rules={[
            {
              required: true,
              message: false,
            },
          ]}
          style={{ fontFamily: 'MojFont' }}>
          <Input  />
        </Form.Item>
        <Form.Item 
          name="nickname"
          label="Надимак"
          tooltip=
          {
            <span style={{ fontFamily: 'MojFont' }}>
              Под којим надимком да Вам се обраћамо?
            </span>
          }
        >
          <Input />
        </Form.Item>
        <Form.Item 
          style={{fontFamily: 'MojFont'}}
          label="Мејл"
          name="email"
          required
          labelAlign="top"
          rules={[
            {
              required: true,
              message: false,
              type: "email",
            },
            {
            validator: (_, value) => {
              if (!value || !/^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/i.test(value)) {
                return Promise.reject('');
              }
              return Promise.resolve();
            },
          },
          ]}
        >
          <AutoComplete
            style={{fontFamily: 'MojFont'}}
            onSearch={handleSearch}
            options={options2}
          >
            <Input/>
          </AutoComplete>
        </Form.Item>
        <Form.Item
          name="tele2"
          label="Телефон"
          rules={[
            {
              required: true,
              message: false,
            },
            {
              validator: (_, value) => {
                if (!value || !/^[+\d]{8,15}$/.test(value)) {
                  return Promise.reject('');
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            inputMode="tel"
            addonBefore={
              <span style={{ fontFamily: 'MojFont', width: 80 }}>
                {prefixSelector}
              </span>
            }
          />
        </Form.Item>
        <Form.Item 
          label="ПИБ"
          name="pib"
          tooltip={
            <span style={{ fontFamily: 'MojFont' }}>
              ИШМ студио је <i>”B2B”</i>
            </span>
          }
          rules={[
            {
              required: true,
              message: false,
            },
            {
              pattern: /^\d{9}$/,
              message: false,
            },
          ]}
          style={{ fontFamily: 'MojFont' }}
        >
          <Input
            inputMode="numeric"
            maxLength={9}
            style={{ fontFamily: 'MojFont'}}
          />
        </Form.Item>
        <Form.Item 
          name="password"
          label="Лозинка"
          rules={[
            {
              required: true,
              message: false,
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="confirm"
          label="Потврди лозинку"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: false,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(''));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <br/>
        <Form.Item>
          <Button 
            type="primary" 
            htmlType="submit" 
            className="login-form-button"
            >
            Пошаљи захтев
          </Button>
        </Form.Item>
        <SLANJE visible1={isModalVisible1} onCancel1={onCancel1} />
      </Form>
      <FloatButton.BackTop />
    </Modal>
  </Form>
};
export default LOGIN;


