import "../../App.css";
import  React, { useState, useEffect } from 'react';
import { ExclamationCircleOutlined, ReloadOutlined
} from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Space, Modal, notification, Spin, Typography
} from 'antd';
import { flushSync } from 'react-dom';  
import { LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  loadCaptchaEnginge,
  validateCaptcha,
 Captcha } from 'react-simple-captcha';
const SLANJE = ({ visible1, onCancel1 }) => {
  const [loading, setLoading] = useState(false);
  const handleSubmit2 = async () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      notification.open({
        placement:'top',
        message: 'Обавештење',
        description: 'Слање није успело, пошаљите мејл...',
        icon: <ExclamationCircleOutlined style={{ color: 'red' }}/>,
        style: { fontFamily: 'MojFont'},
      });
    }, 5000); 
  };
  const onFinish = (values) => {  
    setTimeout(() => {
      onCancel1()
    }, 5000); 
    handleSubmit2();
  };
  const currentDate = new Date();
  const hours = currentDate.getHours();
  const minutes = currentDate.getMinutes().toString().padStart(2, '0');
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1; 
  const year = currentDate.getFullYear();
  const formattedDate = `${hours}.${minutes} ч. ${day}. ${month}. ${year}. године.`;
const [useCyrillic, setUseCyrillic] = useState(true); 
const toggleCaptchaAlphabet = () => {
  setUseCyrillic((prev) => !prev); 
  refreshCaptcha1()
};
  const [captchaValue, setCaptchaValue] = useState('');
  const [inputCaptcha, setInputCaptcha] = useState('');
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const generateCaptchaValue = () => {
    let value = '';
    const characters = useCyrillic
    ? 'АаБбВвГгДдЂђЕеЖжЗзИиЈјКкЛлЉљМмНнЊњОоПпРрСсТтЋћУуФфХхЦцЧчЏџШш0123456789'
    : 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 5; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      value += characters.charAt(randomIndex);
    }
    setCaptchaValue(value);
  };
  const refreshCaptcha = () => {
    generateCaptchaValue();
  };
  const handleInputChange = (e) => {
    setInputCaptcha(e.target.value);
    if (e.target.value.toLowerCase() === captchaValue.toLowerCase()) {
      setIsCaptchaValid(true);
    } else {
      setIsCaptchaValid(false);
    }
  };
  const checkCaptcha = () => {
    if (inputCaptcha.toLowerCase() === captchaValue.toLowerCase()) {
      setIsCaptchaValid(true);
    } else {
      setIsCaptchaValid(false);
    }
  };
useEffect(() => {
  generateCaptchaValue();
}, []);
const [mathCaptcha, setMathCaptcha] = useState('');
const [mathCaptchaResult, setMathCaptchaResult] = useState('');
const [isMathCaptchaValid, setIsMathCaptchaValid] = useState(false);
const generateMathCaptchaValue = () => {
  const operators = ['+', '-'];
  const operator = operators[Math.floor(Math.random() * operators.length)];
  const num1 = Math.floor(Math.random() * 10); 
  const num2 = Math.floor(Math.random() * 10); 
  const mathCaptchaValue = `${num1} ${operator} ${num2}`;
  const result = eval(mathCaptchaValue); 
  setMathCaptcha(`${mathCaptchaValue} =`);
  setMathCaptchaResult(result.toString());
};
useEffect(() => {
  generateMathCaptchaValue();
}, []);
const handleMathCaptchaChange = (e) => {
  const inputValue = e.target.value;
  setInputCaptcha(inputValue);
  if (inputValue === mathCaptchaResult) {
    setIsMathCaptchaValid(true);
  } else {
    setIsMathCaptchaValid(false);
  }
};
useEffect(() => {
  refreshCaptcha1();
}, []);
const refreshCaptcha1 = () => {
  refreshCaptcha()
  generateMathCaptchaValue()
};
  return <Modal 
    open={visible1}
    onCancel={onCancel1}
    footer={null} 
    closable={false} 
    >
    <h1 
      className="h1"
      style={{marginLeft: 0, marginRight: 0}}
    >
      Да ли си аутомат?
    </h1>
    <Form 
      onFinish={onFinish}
      style={{
        fontFamily: 'MojFont', 
        display: 'flex',  
        justifyContent: 'center', 
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      <Space
        name="captcha"
        style={{
          fontFamily: 'MojFont', 
          display: 'flex',  
          justifyContent: 'center', 
          alignItems: 'center',
          flexDirection: 'row',
          width: '100%',
          height: 50,
        }}
      >
        <div 
          className="preview" 
          style={{ 
            display: 'flex',
            justifyContent: 'center', 
            width: 95, 
            height: 30,
            userSelect: 'none', 
          }}>
          {captchaValue &&
            captchaValue.split('').map((char, index) => (
              <span
                key={index}
                style={{
                  userSelect: 'none',
                  transform: `rotate(${Math.random() * 20 - 10}deg)`,
                  fontFamily: 'cursive',
                  fontSize: `${Math.random() * 0.5 + 0.8}em`,
                }}
              >
                {char}
              </span>
          ))}
        </div>
        <div 
          className="preview" 
          style={{ 
            display: 'flex',
            justifyContent: 'center',
            width: 95, 
            height: 30,
            userSelect: 'none', 
            }}>
          {mathCaptcha.split('').map((char, index) => (
            <span
              key={index}
              style={{
                transform: `rotate(${Math.random() * 20 - 10}deg)`,
                fontFamily: 'cursive',
                fontSize: `${Math.random() * 0.5 + 0.8}em`,
                display: 'inline-block',
              }}
            >
              {char}
            </span>
          ))}
        </div>
      </Space >
      <Form.Item
        className="captcha_form"
        style={{
          fontFamily: 'MojFont', 
          display: 'flex',  
          justifyContent: 'center', 
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <Space.Compact       
          style={{
            fontFamily: 'MojFont', 
            display: 'flex',  
            justifyContent: 'center', 
            alignItems: 'center',
          }}
        >
          <Button 
          style={{
            width: 40,
            padding: 0
          }}
          onClick={toggleCaptchaAlphabet}>
            {useCyrillic ? <span style={{fontFamily: 'MojFont'}}>L</span> : <span style={{fontFamily: 'MojFont'}}>Ћ</span>}
          </Button>
          <Input
            className="form_input_captcha"
            onChange={handleInputChange}
            style={{
              width: 100,
              display: 'flex', 
              justifyContent: 'center',
            }}
          />
          <Input 
            className="form_input_captcha"
            onChange={handleMathCaptchaChange}
            inputMode="numeric"
            style={{
              width: 100,
              display: 'flex', 
              justifyContent: 'center',
            }}
          />
          <Button
          style={{
            padding: 0,
            width: 40,
          }}
            className="captcha_refresh"
            icon={<ReloadOutlined />}
            onClick={refreshCaptcha1}
          />
        </Space.Compact>
      </Form.Item>
      <Form.Item 
        name="agreement"
        valuePropName="checked"
        style={{
          fontFamily: 'MojFont', 
          display: 'flex',  
          justifyContent: 'center',
        }}
        rules={[
          {
          validator: (_, value) =>
          value ? Promise.resolve() : Promise.reject(new Error('Морате бити сагласни да би се проследило.')),
          },
        ]}
      >
        <Checkbox 
          style={{fontFamily: 'MojFont',
          }}
          >
            Сагласан сам да се пошаљу подаци на обраду
        </Checkbox>
      </Form.Item>
      <Form.Item>
        <Button type="primary" 
        htmlType="submit" 
        disabled={!isCaptchaValid || !isMathCaptchaValid}
        className="login-form-button" 
        style={{fontFamily: 'MojFont'}}>
          Пошаљи
        </Button>
      </Form.Item>
      {loading && (
      <p 
      style={{marginLeft: 0, marginRight: 0}}
        className="text">
          <Spin 
            tip="slanje..."
            style={{fontFamily: 'MojFont', marginRight: 20}}
            />
        Причекајте, шаље се...
      </p> 
      )}
    </Form>
  </Modal>
};
export default SLANJE;


