import '../../App.css';
import { useState, React, useEffect } from 'react';
import { flushSync } from 'react-dom';  
import {  Col,        Row,    Button, Upload,       Cascader, Checkbox,
  DatePicker, ConfigProvider, Form,   Input,  InputNumber,  Radio,    Select,
  Slider,     Switch, TreeSelect, FloatButton, Card, AutoComplete
  } from 'antd';
import {  UploadOutlined, PlusOutlined, MinusCircleOutlined, UserOutlined, 
          LeftCircleOutlined, RightCircleOutlined, PlusCircleOutlined } 
  from '@ant-design/icons';
import { DndContext, PointerSensor, useSensor } 
  from '@dnd-kit/core';
import { SortableContext, arrayMove, useSortable, verticalListSortingStrategy, } 
  from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { css } from '@emotion/css';
import SLANJE from './SLANJE';
const { Option } = Select;
const { TextArea } = Input;
const KONSALTING = () => {
  const prefixSelector = (
      <Select className="my-select"  style={{ width: 80,}}>
        <Option className="my-select" value="81">+381</Option>
        <Option className="my-select" value="82">+382</Option>
        <Option className="my-select" value="83">+383</Option>
        <Option className="my-select" value="85">+385</Option>
        <Option className="my-select" value="86">+386</Option>
        <Option className="my-select" value="87">+387</Option>
        <Option className="my-select" value="89">+389</Option>
      </Select>
  );
  const [fields, setFields] = useState([{ name: '', id:1 }]);
  const addField = () => {
    const newId = fields.length > 0 ? fields[fields.length - 1].id + 1 : 1;
    setFields([...fields, { name: '', id: newId }]);
  };
  const removeField = (id) => {
    const newFields = fields.filter((field) => field.id !== id);
    setFields(newFields);
  };
  const handleNameChange = (value, id) => {
    const newFields = [...fields];
    const fieldToUpdate = newFields.find((field) => field.id === id);
    if (fieldToUpdate) {
      fieldToUpdate.name = value;
      setFields(newFields);
    }
  };
  const inputStyle = {
    fontFamily: 'MojFont !important', 
    color: 'blue',
  };
  const onFinish = (values) => {    
    showModal();
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
const customStyle = {
  fontFamily: 'MojFont',
};
const [options2, setOptions2] = useState([]);
const [inputValue, setInputValue] = useState('');
const handleSearch = (value) => {
  setInputValue(value);
  let res = [];
  if (!value || value.indexOf('@') >= 0) {
    res = [];
  } else {
    res = ['ism-studio.rs', 'yandex.com', 'yahoo.com', 'vivaldi.net', 'gmail.com'].map((domain) => ({
      value: `${value}@${domain}`,
      style: customStyle,
    }));
  }
  setOptions2(res);
};
const onSelect2 = (value) => {
  setInputValue(value);
};
  return ( <Form         
    onFinish={onFinish}       
    labelCol={{
      span: 10, }}
    wrapperCol={{
      span: 40, }}
    layout="vertical"
    className="custom-form-item"  
    style={{ marginLeft: '5%', marginRight: '5%'}}>
      {fields.map((field, index) => (
      <Form.Item
        className="input-prefix-suffix"
        key={field.id}
        align="baseline"
        name={`name-${field.id}`}
        label={index === 0 ? 'Контакт особа' : `${index + 1}. контакт особа`}
        fieldKey={`name-${field.id}`}
        rules={[{ required: true, message: false }]}
      >
        <Input
          value={field.name}
          onChange={(e) => handleNameChange(e.target.value, field.id)}
          suffix={
            <>
              {fields.length > 1 && (
                <MinusCircleOutlined
                  style={{ marginRight: '4px', cursor: 'pointer' }}
                  onClick={() => removeField(field.id)}
                />
              )}
              <PlusCircleOutlined onClick={addField} style={{ cursor: 'pointer' }} />
            </>
          }
          
        />
      </Form.Item>
      ))}
      <Form.Item
        name="tele"
        label="Телефон"
        rules={[
          {
            required: true,
            message: false,
          },
          {
            validator: (_, value) => {
              if (!value || !/^[+\d]{8,15}$/.test(value)) {
                return Promise.reject('');
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Input
          inputMode="tel"
          style={{ backgroundColor: 'white', borderRadius: '7px' }}
          addonBefore={
            <span style={{ fontFamily: 'MojFont', width: 80 }}>
              {prefixSelector}
            </span>
          }
        />
      </Form.Item>
      <Form.Item
        style={{fontFamily: 'MojFont'}}
        label="Мејл"
        
        name="mejl"
        required
        labelAlign="top"
        
        rules={[
          {
            required: true,
            message: false,
            type: "email",
          },
          {
          validator: (_, value) => {
            if (!value || !/^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/i.test(value)) {
              return Promise.reject('');
            }
            return Promise.resolve();
          },
        },
        ]}
      >
        <AutoComplete
          style={{fontFamily: 'MojFont'}}
          onSearch={handleSearch}
          options={options2}
        >
          <Input inputMode="email"/>
        </AutoComplete>
      </Form.Item>
      <Form.Item 
        label="ПИБ"
        name="pib"
        tooltip={
          <span style={{ fontFamily: 'MojFont' }}>
            ИШМ студио је <i>”B2B”</i>
          </span>
        }
        rules={[
          {
            required: true,
            message: false,
          },
          {
            pattern: /^\d{9}$/,
            message: false,
          },
        ]}
        style={{ fontFamily: 'MojFont' }}
      >
        <Input
          inputMode="numeric"
          maxLength={9}
          style={{ fontFamily: 'MojFont'}}
        />
      </Form.Item>
      <Form.Item 
        label="Број учесника"
        name="bu" 
        rules={[
          {
            type: 'number',
            min: 1,
            max: 10000,
            message: false,
          },
        ]}
        style={{ fontFamily: 'MojFont' }}
      >
        <InputNumber 
          id="obican_input_number"
          inputMode="numeric"
          style={{
            fontFamily: 'MojFont'}}/>
      </Form.Item>
      <Form.Item
        label="Време"
        name="datumVreme"
      >
        <Input
          type="datetime-local"
        />
      </Form.Item>
      <Form.Item 
        label="Повод"
        name="povod"
        rules={[
          {
            required: true,
            message: false,
          },
        ]}>
        <TextArea 
          rows={3} 
          style={{fontFamily: 'MojFont'}}/>
          
      </Form.Item>
      <Form.Item 
        >
        <Button 
          type="primary"
          htmlType="submit" 
          className="login-form-button">
          Пошаљи захтев
        </Button>
        <SLANJE visible1={isModalVisible} onCancel1={handleCancel} />
      </Form.Item>   
    </Form>      
  );
};
export default KONSALTING;