import "../App.css";
import { Divider, Typography, Space, message,
Button, Row, Col, Card, Input, Form, Menu,
List, Icon, Table, FloatButton} from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {CopyOutlined} from '@ant-design/icons';
const { Title, Text, Link } = Typography;
const ONAMA = () => {
  const textIdent = (
    <>
      Идентификациони подаци
    </>
  );
  const CopyButton = ({ text }) => {
    const handleCopy = () => {
      message.success(<span style={{ fontFamily: 'MojFont' }}>Текст је копиран</span>);
    };
    return (
      <CopyToClipboard text={text}>
        <span type="text" block
          style={{ 
            display: 'block', 
            width: '100%',  
            padding: '8px',  
            cursor: 'pointer' }}
          onClick={handleCopy}
          >{text}
          </span>
      </CopyToClipboard>
    );
  };
    const textPunNaziv = 'IŠM STUDIO DOO ERDEVIK';    
    const textSkracen2 = 'IŠM STUDIO DOO';    
    const textOsnovana2 = '2019. године';    
    const textSediste2 = 'Војвођанска 19, 22230 Ердевик';   
    const textPIB2 = '111747822';    
    const textMB2 = '21533181';    
    const textSD2 = '2612';    
    const textPDV2 = 'Да';    
    const textOL2 = 'Сава Вујичић';    
    const textMejl2 = 'info@ism-studio.rs';    
    const textSajt2 = 'ишм-студио.срб';    
    const textDI2 = '340-11025338-43';    
    const textDE2 = '340-10044478-31';
  return <Space direction="vertical">
    <h1 className="h1">О нама</h1>
    <p className="text"> 
      ИШМ Студио је компанија која се бави ембедед развојем са применом у сверама 
      енергетске електронике, обновљивим изворима и <i>IoT</i>-у.
    </p>
    <br/>
    <p className="text"> 
      Од почетне идеје до крајњег производа и серијске производње:
      хардвер, фирмвер, софтвер, производња, подршка и оджавање.
    </p>
    <h1 
    className="h1">
      Идентификациони подаци
    </h1>
    <p 
      className="text2"> 
      Пун назив
    </p>  
    <p 
      className="text3">
      <CopyButton text={textPunNaziv} />
    </p>
    <p
      className="text2">
      Адреса
    </p>  
    <p 
      className="text3" 
      >
        <CopyButton text={textSediste2} href="https://goo.gl/maps/hF9LyZ95MXLBkRgC6" 
      target="_blank"/>
    </p>
    <p 
      className="text2"
      >
      Година оснивања
    </p>  
    <p 
      className="text3">
      <CopyButton text={textOsnovana2} />
    </p>
    <p 
      className="text2">
      ПИБ
    </p>  
    <p 
      className="text3">
      <CopyButton text={textPIB2} />
    </p>
    <p 
      className="text2">
      Матични број
    </p>  
    <p 
      className="text3">
      <CopyButton text={textMB2} />
    </p>
    <p 
      className="text2">
      Шифра делатности
    </p>  
    <p 
      className="text3">
      <CopyButton text={textSD2} />
    </p>
    <p 
      className="text3">
      <CopyButton text={'ПДВ обвезник'} />
    </p>
    <p 
      className="text2">
      Власник и директор
    </p>  
    <p
      className="text3">
      <CopyButton text={textOL2} />
    </p>
    <p 
      className="text2">
      Мејл
    </p>  
    <p
      className="text3">
      <CopyButton text={textMejl2} />
    </p>
    <p 
      className="text2">
      Веб-сајт
    </p>  
    <p
      className="text3">
      <CopyButton text={textSajt2} />
    </p>
    <p 
      className="text2">
      Динарски рачун
    </p>  
    <p
      className="text3">
      <CopyButton text={textDI2} />
    </p>
    <p 
      className="text2">
      Девизни рачун
    </p>  
    <p
      className="text3">
      <CopyButton text={textDE2} />
    </p>
    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
    <FloatButton.BackTop />
  </Space>  
};
export default ONAMA;



