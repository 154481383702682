import "../App.css";
import myImage from "./ism_logo.png";
import { React, useRef, useEffect, useCallback, useState } from 'react';
import { Steps, Divider, Typography, Space, Button, Carousel, Card, Image, FloatButton } from 'antd';
const { Title, Text, Link } = Typography;
const { Step } = Steps;
const contentStyle = {
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};
const POCETNA = ({doUpita}) => {
  const [activeCard, setActiveCard] = useState(0);
  useEffect(() => {
    const timer = setTimeout(() => {
      setActiveCard((prevActiveCard) => (prevActiveCard + 1) % 7);
    }, 3000); 
    return () => clearTimeout(timer);
  }, [activeCard]);
  const [current, setCurrent] = useState(0);
  const onChange = (value) => {
    console.log('onChange:', value);
    setCurrent(value);
  };
  return <Space direction="vertical"
    style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      maxWidth: '100%', 
      maxHeight: '100%', 
      fontFamily: 'MojFont',
      marginTop: 30,
    }}>
    <img
      style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        maxWidth: '90%', 
        maxHeight: '100%', 
        fontFamily: 'MojFont',
        marginTop: 30,
        marginLeft: '5%', 
        marginRight: '5%'
      }} 
      src={myImage} alt="Moja slika"
    />
<br/>
    <h1 
    className="h1"> Од почетне идеје до готовог производа</h1>
    <h1 
     className="h1"> Потпуна реализација електронских уређаја почевши
    од дизајнерских услуга све до серијске производње</h1>
    <Divider />
    <h1 
    className="h1">Дизајнирање штампаних плоча</h1>
    <p className="text">
      Главна делатност је вршење дизајнерских услуга и израда докумената за 
      електронске уређаје који се користе у областима енергетске електронике 
      и телеметрије, као и понуда најоптималнијих и најадекватнијих хардверских решења.
    </p>
    <h1 
    className="h1">Систем производње</h1>
    <p className="text">
    Све што ми дизајнирамо можемо одмах реализовати.
    Првенствено се бавимо израдом прототипова, а по жељи и комплетне серијске производње.
    У процесу производње раде се набавке, лемљење, склапање и врше са сва неопходна мерења,
    тестирања, спремања документације и сертификовање.
    </p>
    <h1  
    className="h1">Тестне платформе</h1>
    <p className="text">
    Ако Ваш уређај треба да се тестира, производи, нашим тестним уређајима приступамо <i>Test Point</i>-има и 
    вршимо анализу вашег уређаја и повезујемо са платформама за тестирање и надзор, и на основу којих 
    се доносе извештаји тестирања.
    </p>
    <h1 
      className="h1">
      Како се започињу пројекти? И која је процедура развоја?
    </h1>
    {activeCard === 0 && (
      <Card 
        bordered={false}
        style={{ 
          width: 400,
          height: 700,
          fontFamily: 'MojFont', 
          background: 'rgba(51, 102, 153, 0.1)',
          display: 'grid',
          alignContent: 'center',
        }}
      >
        <Steps 
          current={current}
          onChange={onChange}
          direction="vertical"
          style={{ 
            display: 'grid',
            alignContent: 'center',
          }}
          items={[
            {
              title: 'Упит',
              description: '~1 дан',
            },
            {
              title: 'Идејно решење',
              description: '~7 дана'
            },
            {
              title: 'Студија изводљивости',
              description: '~45 дана'
            },
            {
              title: 'Плана развоја',
              description: '~7 дана'
            },
            {
              title: 'Прва фаза развоја',
              description: '~45 дана'
            },
            {
              title: 'Друга фаза развоја',
              description: '~45 дана'
            },
            {
              title: 'Трећа фаза развоја',
              description: '~45 дана'
            },
            {
              title: 'Тестирање',
              description: '~7 дана'
            },
            {
              title: 'Уговор о производњи',
              description: '~1 дан'
            },
            {
              title: 'Производња, одржавање, подршка',
              description: '...',
            },
          ]}
        />
      </Card>
    )}
    {activeCard === 1 && (
      <Card
        bordered={false}
        style={{
          width: 400,
          height: 700,
          background: 'rgba(51, 102, 153, 0.1)',
          display: 'grid',
          alignContent: 'center',
        }}
      >
        <Steps 
          current={current}
          onChange={onChange}
          direction="vertical"
          style={{
            display: 'grid',
            alignContent: 'center',
          }}
          items={[
            {
              title: 'Упит',
              description: '~1 дан'
            },
            {
              title: 'Развој',
              description: '~50 дана'
            },
            {
              title: 'Кључ у руке',
              description: '~1 дан'
            },
            {
              title: 'Производња и одржавање',
              description: 'Уговорно'
            },
          ]}
        />
      </Card>
    )}
    {activeCard === 2 && (
      <Card
        bordered={false}
        style={{ 
          width: 400,
          height: 700,
          fontFamily: 'MojFont',
          background: 'rgba(51, 102, 153, 0.1)',
          display: 'grid',
          alignContent: 'center',
        }}
      >
        <Steps 
          current={current}
          onChange={onChange}
          direction="vertical"
          style={{
            display: 'grid',
            alignContent: 'center',
          }}
          items={[
            {
              title: 'Упит',
              description: '~1 дан'
            },
            {
              title: 'Развој',
              description: '~35 дана'
            },
            {
              title: 'Тестирање',
              description: '~3 дана'
            },
            {
              title: 'Испорука',
              description: '~1 дан'
            },
          ]}
        />
      </Card>
    )}
    {activeCard === 3 && (
      <Card
        bordered={false}
        style={{ 
          width: 400,
          height: 700,
          fontFamily: 'MojFont',
          background: 'rgba(51, 102, 153, 0.1)',
          display: 'grid',
          alignContent: 'center',
        }}
      >
        <Steps 
          current={current}
          onChange={onChange}
          direction="vertical"
          style={{
            display: 'grid',
            alignContent: 'center',
          }}
          items={[
            {
              title: 'Упит',
              description: '~1 дан'
            },
            {
              title: 'Дефинисање плана производње',
              description: '~3 дана'
            },
            {
              title: 'Набавка',
              description: '~45 дана'
            },
            {
              title: 'Адаптација производних капацитета',
              description: '~70 дана'
            },
            {
              title: 'Производња',
              description: '~годину дана'
            },
          ]}
        />
      </Card>
    )}
    {activeCard === 4 && (
      <Card
        bordered={false}
        style={{ 
          width: 400,
          height: 700,
          fontFamily: 'MojFont',
          background: 'rgba(51, 102, 153, 0.1)',
          display: 'grid',
          alignContent: 'center',
        }}
      >
        <Steps 
          current={current}
          onChange={onChange}
          direction="vertical"
          style={{
            display: 'grid',
            alignContent: 'center',
          }}
          items={[
            {
              title: 'Упит',
              description: '~1 дан'
            },
            {
              title: 'Студија изводљивости',
              description: '~10 дана'
            },
            {
              title: 'Дефинисање плана развоја',
              description: '~4 дана'
            },
            {
              title: 'Прва фаза развоја',
              description: '~45 дана'
            },
            {
              title: 'Тестирање',
              description: '~3 дана'
            },
            {
              title: 'Друга фаза развоја',
              description: '~45 дана'
            },
            {
              title: 'Тестирање',
              description: '~3 дана'
            },
            {
              title: 'Испорука',
              description: '~1 дана'
            },
            {
              title: 'Дефинисање плана производње',
              description: '~15 дана'
            },
            {
              title: 'Производња',
              description: '~годину дана'
            },
          ]}
        />
      </Card>
    )}
    {activeCard === 5 && (
      <Card
        bordered={false}
        style={{ 
          width: 400,
          height: 700,
          fontFamily: 'MojFont',
          background: 'rgba(51, 102, 153, 0.1)',
          display: 'grid',
          alignContent: 'center',
        }}
      >
        <Steps 
          current={current}
          onChange={onChange}
          direction="vertical"
          style={{
            display: 'grid',
            alignContent: 'center',
          }}
          items={[
            {
              title: 'Упит',
              description: '~1 дан'
            },
            {
              title: 'Израда пројекта',
              description: '~30 дана'
            },
            {
              title: 'Тестирање',
              description: '~4 дана'
            },
          ]}
        />
      </Card>
    )}
    {activeCard === 6 && (
      <Card
        bordered={false}
        style={{ 
          width: 400,
          height: 700,
          fontFamily: 'MojFont',
          background: 'rgba(51, 102, 153, 0.1)',
          display: 'grid',
          alignContent: 'center',
        }}
      >
        <Steps 
          current={current}
          onChange={onChange}
          direction="vertical"
          style={{
            display: 'grid',
            alignContent: 'center',
          }}
          items={[
            {
              title: 'Упит',
              description: '~1 дан'
            },
            {
              title: 'Израда прототипа',
              description: '~40 дана'
            },
            {
              title: 'Тестирање',
              description: '~3 дана'
            },
            {
              title: 'Производња пробна серије',
              description: '~7 дана'
            },
          ]}
        />
      </Card>
    )}
<h1 className="h1">Започни пројекат и пошаљи упит</h1>
    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
    <FloatButton.BackTop />
  </Space>
};
export default POCETNA;