import '../../App.css';
import { useState, React } from 'react';
import {  Col,        Row,    Button, Upload,       Cascader, Checkbox,
  DatePicker, ConfigProvider, Form,   Input,  InputNumber,  Radio,    Select,
  Slider,     Switch, TreeSelect, FloatButton, Card, AutoComplete
  } from 'antd';
import SLANJE from './SLANJE';
const customStyle = {
  fontFamily: 'MojFont',
};
const options3 = [
  { 
    value: 'Солар',
    style: customStyle, 
  },
  { 
    value: 'Енергетски претварачи', 
    style: customStyle, 
  },
  { 
    value: 'Телеметрија', 
    style: customStyle, 
  },
  { 
    value: 'IOT', 
    style: customStyle, 
  },
  { 
    value: 'Паметне куће', 
    style: customStyle, 
  },
  { 
    value: 'Агрономиа', 
    style: customStyle, 
  },
  { 
    value: 'SCADA', 
    style: customStyle, 
  },
  { 
    value: 'Гас', 
    style: customStyle, 
  },
  { 
    value: 'Медицина', 
    style: customStyle, 
  },
];
const { Option } = Select;
const { TextArea } = Input;
const PLATFORMA = () => {
  const onFinish = (values) => {    
    showModal();
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const prefixSelector = (
    <Select className="my-select"  style={{ width: 80,}}>
      <Option className="my-select" value="81">+381</Option>
      <Option className="my-select" value="82">+382</Option>
      <Option className="my-select" value="83">+383</Option>
      <Option className="my-select" value="85">+385</Option>
      <Option className="my-select" value="86">+386</Option>
      <Option className="my-select" value="87">+387</Option>
      <Option className="my-select" value="89">+389</Option>
    </Select>
  );
const [options2, setOptions2] = useState([]);
const [inputValue, setInputValue] = useState('');
const handleSearch = (value) => {
  setInputValue(value);
  let res = [];
  if (!value || value.indexOf('@') >= 0) {
    res = [];
  } else {
    res = ['ism-studio.rs', 'yandex.com', 'yahoo.com', 'vivaldi.net', 'gmail.com'].map((domain) => ({
      
      value: `${value}@${domain}`,
      style: customStyle,
    }));
  }
  setOptions2(res);
};
const onSelect2 = (value) => {
  setInputValue(value);
};
  return (  <Form         
    onFinish={onFinish}        
    labelCol={{
      span: 10, }}
    wrapperCol={{
      span: 40, }}
    layout="vertical"
    className="custom-form-item"  
    style={{ marginLeft: '5%', marginRight: '5%'}}>
      <Form.Item 
        label="Име"
        name="ime"
        rules={[
          {
            required: true,
            message: false,
          },
        ]}
        style={{ fontFamily: 'MojFont' }}>
        <Input style={{fontFamily: 'MojFont'}}/>
      </Form.Item>
      <Form.Item
        label="Презиме"
        name="Презиме"
        rules={[
          {
            required: true,
            message: false,
          },
        ]}
        style={{ fontFamily: 'MojFont' }}>
        <Input style={{fontFamily: 'MojFont'}}/>
      </Form.Item>
      <Form.Item
        style={{fontFamily: 'MojFont'}}
        label="Мејл"
        name="mejl"
        required
        labelAlign="top"
        rules={[
          {
            required: true,
            message: false,
            type: "email",
          },
          {
          validator: (_, value) => {
            if (!value || !/^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/i.test(value)) {
              return Promise.reject('');
            }
            return Promise.resolve();
          },
        },
        ]}
      >
        <AutoComplete
          style={{fontFamily: 'MojFont'}}
          onSearch={handleSearch}
          options={options2}
        >
          <Input inputMode="email"/>
        </AutoComplete>
      </Form.Item>
      <Form.Item
        name="tele"
        label="Телефон"
        rules={[
          {
            required: true,
            message: false,
          },
          {
            validator: (_, value) => {
              if (!value || !/^[+\d]{8,15}$/.test(value)) {
                return Promise.reject('');
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Input
          inputMode="tel"
          style={{ backgroundColor: 'white', borderRadius: '7px' }}
          addonBefore={
            <span style={{ fontFamily: 'MojFont', width: 80 }}>
              {prefixSelector}
            </span>
          }
        />
      </Form.Item>
      <Form.Item
        label="ПИБ"
        name="pib"
        tooltip={
          <span style={{ fontFamily: 'MojFont' }}>
            ИШМ студио је <i>”B2B”</i>
          </span>
        }
        rules={[
          {
            required: true,
            message: false,
          },
          {
            pattern: /^\d{9}$/,
            message: false,
          },
        ]}
        style={{ fontFamily: 'MojFont' }}
      >
        <Input
          inputMode="numeric"
          maxLength={9}
          style={{ fontFamily: 'MojFont'}}
        />
      </Form.Item>
      <Form.Item 
        label="Област">
        <AutoComplete
          popupClassName="certain-category-search-dropdown"
          style={{
            fontFamily: 'MojFont'
          }}
          options={options3}
        >
          <Input style={{
            fontFamily: 'MojFont'
            }}
            size="large" 
            />
        </AutoComplete>
      </Form.Item>
      <Form.Item
        label="Опис"
        name="OP"
        tooltip={
          <span style={{ fontFamily: 'MojFont' }}>
            У кратким цртама опишите какав вид платформе Вам је потребан.
          </span>
        }
        rules={[
          {
            required: true,
            message: 'Опишите какава Вам је платформа потребна.',
          },
        ]}>
        <TextArea rows={4} style={{fontFamily: 'MojFont'}}/>
      </Form.Item>
      <Form.Item
      >
        <Checkbox style={{ fontFamily: 'MojFont' }}>
          Демо верзија?
        </Checkbox>
      </Form.Item>
      <Form.Item >
        <Button 
          type="primary"
          htmlType="submit" 
          className="login-form-button">
          Пошаљи захтев
        </Button>
      </Form.Item>
      <SLANJE visible1={isModalVisible} onCancel1={handleCancel} />
    </Form> 
  );
};
export default PLATFORMA;