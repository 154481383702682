import "../App.css";
import React, { useState } from 'react';
import {  Divider, Typography, Space, Col, Row, Card, Input,
          Form, Button, Menu, List, Icon, message, FloatButton, AutoComplete } from 'antd';
import { LinkedinOutlined, DashboardOutlined} from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useNavigate } from "react-router-dom"
import SLANJE from './Upit/SLANJE';
const { Title, Text, Link } = Typography;
const KONTAKT = () => {
  const onFinish = (values) => {    
    visible1();
  };
  const [isModalVisible1, setIsModalVisible1] = useState(false);
  const visible1 = () => {
    setIsModalVisible1(true);
  };
  const handleOk1 = () => {
    setIsModalVisible1(false);
  };
  const onCancel1 = () => {
    setIsModalVisible1(false);
  };
 const customStyle = {
  fontFamily: 'MojFont',
};
const [options2, setOptions2] = useState([]);
const [inputValue, setInputValue] = useState('');
const handleSearch = (value) => {
  setInputValue(value);
  let res = [];
  if (!value || value.indexOf('@') >= 0) {
    res = [];
  } else {
    res = ['ism-studio.rs', 'yandex.com', 'yahoo.com', 'vivaldi.net', 'gmail.com'].map((domain) => ({
      value: `${value}@${domain}`,
      style: customStyle,
    }));
  }
  setOptions2(res);
};
const onSelect2 = (value) => {
  setInputValue(value);
};
  const [form] = Form.useForm();
  const { Title, Text } = Typography;
  const navigate = useNavigate()
  const textAdresa = 'Војвођанска 19, Ердевик';
  const textMejl = 'info@ism-studio.rs';
  const textTelefon = '+381649330827';
  const textMB = '21533181';
  const textPIB = '111747822';
  const textPDV = 'ПДВ обвезник';
  const textDI = '340-11025338-43';
  const textDE = '340-10044478-31';
  const textDir = 'Сава Вујичић';
  const CopyButton = ({ text }) => {
    const handleCopy = () => {
      message.success(<span style={{ fontFamily: 'MojFont' }}>
      Текст је копиран.
    </span>);
    };
    return (
      <CopyToClipboard text={text}>
              <span type="text" block
        style={{ display: 'block', width: '100%',  padding: '8px',  cursor: 'pointer' }}
        onClick={handleCopy}
      >
        {text}
      </span>
      </CopyToClipboard>
    );
  };
  const data = [
    {
      title: 'Мејл',
      Opis: <CopyButton text={textMejl}/>,
    },
    {
      title: 'Телефон',
      Opis: <CopyButton text={textTelefon} />,
    },
    {
      title: 'Адреса',
      Opis: <CopyButton text={textAdresa} />,
    },
    {
      title: 'МБ',
      Opis: <CopyButton text={textMB} />,
    },
    {
      title: 'ПИБ',
      Opis: <CopyButton text={textPIB} />,
    },
    {
      title: <CopyButton text={textPDV} />,
    },
    {
      title: 'Динарски рачун',
      Opis: <CopyButton text={textDI} />,
    },
    {
      title: 'Девизни рачун',
      Opis: <CopyButton text={textDE} />,
    },
    {
      title: 'Власник и директор',
      Opis: <CopyButton text={textDir} />,
    },
  ];
  return <div>
    <h1 className="h1">Контакт</h1>
    <br/>
    <Row className="kontakt1">
      <Col span={12} className="text" style={{ textAlign: 'right',  padding: 4, margin: 0}}
      >
      Адреса: <br/>
      <br/>
      Мејл: <br/>
      Телефон:<br/>
      MB: <br/>
      ПИБ: <br/>
      Динарски рачун: <br/>
      Девизни рачун:<br/>
      <br/><br/>
      </Col>
      <Col span={12} className="text" style={{ textAlign: 'left', padding: 4, margin: 0}}>
      Војвођанска 19<br/>
      22230 Ердевик<br/>
      info@ism-studio.rs<br/>
      +381649330827<br/>
      21533181<br/>
      111747822<br/>
      340-11025338-43<br/>
      340-10044478-31<br/>
      <br/><br/>
      </Col>
    </Row>
    <div style={{ textAlign: 'center', fontFamily: 'MojFont', display: 'flex', padding: 20}}>
    <List
              itemLayout="horizontal"
              dataSource={data}
              className="kontakt2"
              renderItem={(item) => (
              <List.Item 
                style={{ 
                  display: 'flex', 
                  fontFamily: 'MojFont', 
                  textAlign: 'center',  
                  marginLeft: 80, 
                  marginRight: 80,
                  fontSize: '20px',
                  background: 'transparent', 
                  opacity: 0.9,
                  width: '200px',
                }}> 
                <List.Item.Meta 
                  avatar={item.Logo}
                  title={item.title}
                  description={item.Opis}/>
              </List.Item>
              )}
            />
            <Card 
              title="Контакт форма" 
              className="card-upit"
              bordered={false}
              style={{ height: '100%' }}
            >
              <Form 
                name="contact-us"
                onFinish={onFinish}
                form={form}
                layout="vertical"
                className="custom-form-item"  
                style={{ marginLeft: '12%', marginRight: '12%'}}> 
                <Form.Item
                  label="Име"
                  name="firstName"
                  required
                  rules={[
                    {
                      required: true,
                      message: false,
                    },
                  ]}>
                  <Input 
                    style={{ fontFamily: 'MojFont' }}/>
                </Form.Item> 
                <Form.Item 
                  style={{fontFamily: 'MojFont'}} 
                  label="Презиме"
                  name="lastName"
                  required
                  rules={[
                    {
                      required: true,
                      message: false,
                    },
                  ]}>
                  <Input 
                  style={{ fontFamily: 'MojFont' }}/>
                </Form.Item>
                <Form.Item 
                  style={{fontFamily: 'MojFont'}}
                  label="Мејл"
                  name="email"
                  required
                  labelAlign="top"
                  rules={[
                    {
                      required: true,
                      message: false,
                      type: "email",
                    },
                    {
                    validator: (_, value) => {
                      if (!value || !/^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/i.test(value)) {
                        return Promise.reject('');
                      }
                      return Promise.resolve();
                    },
                  },
                  ]}
                >
                  <AutoComplete
                    style={{fontFamily: 'MojFont'}}
                    onSearch={handleSearch}
                    options={options2}
                  >
                    <Input/>
                  </AutoComplete>
                </Form.Item>
                <Form.Item
                  style={{fontFamily: 'MojFont'}}
                  label="Текст..."
                  name="message"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Унесите поводом чега нас контактирате...",
                    },
                  ]}>
                  <Input.TextArea 
                    style={{
                      fontFamily: 'MojFont'}}
                    autoSize={{ minRows: 4, maxRows: 6 }}/>
                </Form.Item>
                <Form.Item 
                  >
                  <Button 
                    type="primary" 
                    htmlType="submit" 
                    style={{ fontFamily: 'MojFont' }}>
                      Пошаљи
                  </Button>
                </Form.Item>
              </Form>
              <SLANJE visible1={isModalVisible1} onCancel1={onCancel1} />
            </Card>
    </div>
    <FloatButton.BackTop />
  </div>;
};
export default KONTAKT;