import "../App.css";
import React from 'react';
import { Image, Collapse, Divider, Typography, Space, message, Button, Row,
  Col, Card, Input, Form, Menu, List, Icon, FloatButton, ConfigProvider } from 'antd';
const { Title, Text, Link } = Typography;
const text =   
  <p
    className="text"
    style={{ color: 'red' }}> 
    Страница је у развоју...
  </p>
const items = [
  {
    key: '1',
    label: <span style={{ fontFamily: 'MojFont' }}>Соларне електране</span>,
    children: <p>{text}</p>,
  },
  {
    key: '2',
    label: <span style={{ fontFamily: 'MojFont' }}>Енергецка електроника</span>,
    children: <p>{text}</p>,
  },
  {
    key: '3',
    label: <span style={{ fontFamily: 'MojFont' }}>Ембедед</span>,
    children: <p>{text}</p>,
  },
];
const ISMEDU = () =>  { 
  return <div>
    <h1 className="h1">ИШМ ЕДУ</h1>
    <p className="text"> 
      ИШМ ЕДУ је едукативни програм ИШМ Студиа.
      За сва питања везана за ИШМ ЕДУ контактирати на мејл: ism.edu@ism-studio.rs, или info@ism-studio.rs
    </p>
    <br/>
    <Collapse accordion items={items} />
    <h1 className="h1">Последње додато</h1>
    <p 
      className="text"
      style={{ color: 'red' }}>
      Страница је у развоју...
    </p>
<FloatButton.BackTop />
</div>
};
export default ISMEDU;